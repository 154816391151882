import type { FC } from 'react';
import type { ValidateResult } from 'react-hook-form';

import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getFieldValue } from '@/services/forms';
import { compareToField } from '@/services/validation';

function getErrorMessage( comparisonType: 'greater' | 'less' | 'equal', targetSystemId: string, customError?: string ) {
	const separator = comparisonType === 'equal' ? 'to' : 'than';

	return customError || `This value must be ${ comparisonType } ${ separator } ${ targetSystemId }`;
}

export function withSelectOneFieldComparisonValidation( WrappedComponent: FC<ValidatedComponentProps> ) {
	const NewComponent = ( props: ValidatedComponentProps ) => {
		const { question, validators } = props;
		const { getValues } = useFormContext();

		const matchedValidators = useMemo( () => {
			return validators.filter( validator => validator.fieldType === 'SelectOneFieldComparison' );
		}, [ validators ] );

		const validationObject = useMemo( () => {
			const returnValue: Record<string, ( v: string ) => ValidateResult> = { ...question.validate };

			matchedValidators.forEach( ( validator, index ) => {
				returnValue[ `comparison${ index }` ] = ( v: string ) => {
					// first, check to see if at least one of them is selected
					const thisValidator = validator as SelectOneFieldComparison;
					const allIds = thisValidator.selectOneFields.map( obj => [ question.idPrefix, obj.systemId ].filter( a => a ).join( '.' ) );
					const currentValues = getValues( allIds );

					const setValues = allIds.map( ( id, index ) => {
						return {
							id: id,
							compareToId: thisValidator.selectOneFields[ index ].compareToId,
							comparisonType: thisValidator.selectOneFields[ index ].comparisonType,
							customError: thisValidator.selectOneFields[ index ].customError,
							value: getFieldValue( currentValues[ index ] )
						};
					});

					const filteredSetValues = setValues.filter( a => a.value && a.value !== '0' );

					if ( filteredSetValues.length === 0 || filteredSetValues.length > 1 ) {
						return thisValidator.selectOneCustomError || 'Must select one value';
					}

					// then check to see if the set values compare correctly
					const comparisons = setValues.filter( a => {
						return a.value && a.value !== '0'
					}).map( obj => {
						if ( !obj.compareToId ) {
							return {
								...obj,
								comparison: true
							};
						}

						const comparisonId = [ question.idPrefix, obj.compareToId ].filter( a => a ).join( '.' );

						const sourceValue = obj.value;
						const targetValue = getFieldValue( getValues( comparisonId ) );

						const comparisonType = obj.comparisonType;

						return {
							...obj,
							comparison: compareToField( sourceValue, targetValue, comparisonType )
						};
					}).filter( a => !a.comparison );

					if ( comparisons.length > 0 ) {
						const firstComparison = comparisons[ 0 ];

						return getErrorMessage( firstComparison.comparisonType, firstComparison.compareToId, firstComparison.customError );
					} else {
						return true;
					}
				};
			});

			return returnValue;
		}, [ getValues, matchedValidators, question ] );

		return (
			<WrappedComponent
				{ ...props }
				question={{
					...question,
					validate: validationObject
				}}
			/>
		);
	};

	NewComponent.displayName = 'withSelectOneFieldComparisonValidation';

	return NewComponent;
}
