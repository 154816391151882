import type { FC } from 'react';
import type { ValidateResult } from 'react-hook-form';

import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getFieldValue } from '@/services/forms';
import { compareToField } from '@/services/validation';

function getErrorMessage( comparisonType: 'greater' | 'less' | 'equal', targetSystemId: string, customError?: string ) {
	const separator = comparisonType === 'equal' ? 'to' : 'than';

	return customError || `This value must be ${ comparisonType } ${ separator } ${ targetSystemId }`;
}

export function withFieldComparisonValidation( WrappedComponent: FC<ValidatedComponentProps> ) {
	const NewComponent = ( props: ValidatedComponentProps ) => {
		const { question, validators } = props;
		const { isRequired } = question;

		const { getValues } = useFormContext();

		const comparisonValidators = useMemo( () => {
			return validators.filter( validator => validator.fieldType === 'FieldComparison' );
		}, [ validators ] );

		const validationObject = useMemo( () => {
			const returnValue: Record<string, ( v: string ) => ValidateResult> = { ...question.validate };

			comparisonValidators.forEach( ( validator, index ) => {
				returnValue[ `comparison${ index }` ] = ( v: string ) => {
					if ( !isRequired && !v ) {
						return true;
					}

					const thisValidator = validator as ValidationFieldComparison;

					const targetId = [ question.idPrefix, thisValidator.targetSystemId ].filter( a => a ).join( '.' );

					const targetFieldValue = getFieldValue( getValues( targetId ) );
					const currentFieldValue = getFieldValue( v );
					const comparisonType = thisValidator.comparisonType;
					
					return compareToField( currentFieldValue, targetFieldValue, comparisonType ) || getErrorMessage( comparisonType, thisValidator.targetSystemId, thisValidator.customError );
				};
			});

			return returnValue;
		}, [ comparisonValidators, getValues, isRequired, question ] );

		return (
			<WrappedComponent
				{ ...props }
				question={{
					...question,
					validate: validationObject
				}}
			/>
		);
	};

	NewComponent.displayName = 'withFieldComparisonValidators';

	return NewComponent;
}
