import type { ChangedItems } from '@/definitions/effectiveChangeTable';

import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '@/components/Button';
import Header from '@/components/Header';
import MessageBox from '@/components/MessageBox';
import Table from '@/components/Table';
import { Date } from '@/components/FormElements';

import { changedItemsColumns } from '@/definitions/effectiveChangeTable';
import { compareDates, getDateFromSquidex } from '@/services/dates';

// TODO: Add this to Squidex
const effectiveDateMessageList: {
	id: string;
	dateStr: string;
	comparison: 'before' | 'after';
	message: string;
}[] = [
	{
		id: 'post-june-1',
		dateStr: '06/01/2025 00:00:00',
		comparison: 'after',
		message: 'As of 6/1/2025, deductibles lower than $500 are no longer available.'
	}
];

type ChangeEffectiveDateProps = {
	currentCppEffectiveDate: string,
	title?: string;
	onCancel?: () => void;
	onSuccess?: ( cppEffectiveDate: Date ) => Promise<void>;
	onCheck?: ( newEffectiveDate: Date ) => any;
};

type PostChangeDifferencesProps = {
	changedItems: ChangedItems[];
	changedForms: ChangedItems[];
};

function PostChangeDifferences({
	changedItems,
	changedForms
}: PostChangeDifferencesProps ) {
	const combinedChanges = useMemo( () => {
		return [ ...changedItems, ...changedForms ];
	}, [ changedItems, changedForms ] );

	return (
		<div className="mt-4">
			{ combinedChanges.length > 0 ? (
				<>
					<p className="mb-4">Please note that changing the effective date means the following coverage options may have also changed.</p>

					<div className="mb-6">
						<Table
							variant="mini"
							data={ combinedChanges }
							columns={ changedItemsColumns }
						/>
					</div>
				</>
			) : (
				<p className="mb-4">Changing the effective date will not result in any coverage changes.</p>
			)}
		</div>
	);
}

export default function ChangeEffectiveDate({
	title,
	onCancel = () => {},
	onSuccess = async () => {},
	onCheck
}: ChangeEffectiveDateProps ) {
	const [ changedForms, setChangedForms ] = useState<ChangedItems[] | undefined>( undefined );
	const [ changedItems, setChangedItems ] = useState<ChangedItems[] | undefined>( undefined );
	const [ pendingDate, setPendingDate ] = useState<Date | undefined>();
	const [ effectiveDateMessages, setEffectiveDateMessages ] = useState<string[]>( [] );
	const [ messageDate, setMessageDate ] = useState<Date | undefined>();
	const { handleSubmit, reset } = useFormContext();

	const june1 = getDateFromSquidex( '06/01/2025 00:00:00' );

	const clearPending = useCallback( () => {
		setChangedForms( undefined );
		setChangedItems( undefined );
		setPendingDate( undefined );
		setMessageDate( undefined );
		setEffectiveDateMessages( [] );
	}, [ setChangedForms, setChangedItems, setEffectiveDateMessages, setMessageDate, setPendingDate ] );

	const onClear = useCallback( () => {
		reset();
		clearPending();
		onCancel();
	}, [ onCancel, reset, clearPending ] );

	const finalize = useCallback( async ( effectiveDate?: Date ) => {
		if ( effectiveDate ) {
			await onSuccess( effectiveDate );

			onClear();
		}
	}, [ onClear, onSuccess ] );

	const onSubmit = useCallback( async ( data: any ) => {
		let shouldPause = false;

		// if the onCheck function is there, do the check
		if ( typeof onCheck !== 'undefined' ) {
			const checkResults = await onCheck( data.cppEffectiveDate );
	
			setChangedItems( checkResults.changedItems );
			setChangedForms( checkResults.changedForms );
			setPendingDate( data.cppEffectiveDate );

			shouldPause = true;
		}

		// if there are messages to display for this effective date, show them
		const activeMessages = effectiveDateMessageList.map( ( messageObject ) => {
			if ( compareDates( messageObject.dateStr, data.cppEffectiveDate, messageObject.comparison ) ) {
				return messageObject.message;
			}
		}).filter( a => a );

		setEffectiveDateMessages( activeMessages as string[] );

		if ( activeMessages.length > 0 ) {
			setMessageDate( data.cppEffectiveDate );
			shouldPause = true;
		}

		// if either of the previous checks happened, pause and let the user confirm,
		// otherwise, finalize
		if ( shouldPause ) {
			return;
		}

		finalize( data.cppEffectiveDate );
	}, [ finalize, onCheck ] );

	return (
		<div>
			<Header element="h2" variant="h3">{ title || "Change CPP Effective Date" }</Header>

			{ !pendingDate && (
				<form onSubmit={ handleSubmit( onSubmit ) }>
					<Date
						label="CPP Effective Date"
						systemId="cppEffectiveDate"
					/>

					<div className="flex gap-x-4 mt-4">
						<Button type="submit" variant="primary" size="medium">Continue</Button>
						<Button type="button" variant="border" size="medium" onClick={ () => onClear() }>Cancel</Button>
					</div>
				</form>
			)}

			{ effectiveDateMessages.length > 0 && (
				<MessageBox
					size="small"
					variant="neutral"
					className="mt-4"
					rounded
				>
					<div>
						{ effectiveDateMessages.map( ( message, index ) => (
							<div key={ `message-${ index }` } className="my-2">{ message }</div>
						))}

						<div className="flex gap-x-4 mt-4 mb-2">
							<Button type="button" variant="primary" size="medium" onClick={ () => finalize( messageDate ) }>Copy CPP</Button>
						</div>
					</div>
				</MessageBox>
			)}

			{ ( changedItems !== undefined && changedForms !== undefined ) && (
				<>
					<PostChangeDifferences
						changedForms={ changedForms }
						changedItems={ changedItems }
					/>

					<div className="flex gap-x-4 mt-4">
						<Button type="button" variant="primary" size="medium" onClick={ () => finalize( pendingDate ) }>Submit</Button>
						<Button type="button" variant="border" size="medium" onClick={ () => clearPending() }>Cancel</Button>
					</div>
				</>
			)}
		</div>
	);
}
