import { getFieldValue } from '@/services/forms';

export function validatePhone( val: string, isRequired: boolean ) {
	if ( !isRequired && !!val ) {
		return true;
	}

	return val?.length === 10 || 'Phone numbers must contain 10 digits';
}

export function maxLength( val: string, maxLength: number ) {
	if ( !val ) {
		return true;
	}

	return val.length <= maxLength || `This field may not contain more than ${ maxLength } characters`;
}

export function isNumeric( val: any, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}

	return !isNaN( val ) || 'This value must be numeric';
}

export function noCommas( val: string ) {
	return !val || val.indexOf(',') === -1; 
}

export function isLessThan( val: any, maxValue: number, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}

	return val <= maxValue || `This value may not be greater than ${ maxValue }`;
}

export function isGreaterThan( val: any, minValue: number, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}

	return val >= minValue || `This value must be at least ${ minValue }`;
}

export function isBetween( val: any, minValue: number, maxValue: number, isRequired: boolean ) {
	if ( !isRequired && !val ) {
		return true;
	}
	
	return val >= minValue && val <= maxValue || `This value must be between ${ minValue } and ${ maxValue }`;
}

export function compareToField( val: any, targetFieldValue: string, comparison: 'greater' | 'less' | 'equal' ) {
	const comparisonNumber = Number( val );
	const targetNumber = Number( targetFieldValue );

	switch ( comparison ) {
		case 'greater': {
			return comparisonNumber >= targetNumber;
		}

		case 'less': {
			return comparisonNumber <= targetNumber;
		}

		case 'equal': {
			return comparisonNumber == targetNumber;
		}

		default: {
			return false;
		}
	}
}

export function includeAtLeastOne( fieldIds: { systemId: string }[], getValues: any, setError: any, customError?: string, idPrefix?: string ) {
	const allIds = fieldIds.map( obj => [ idPrefix, obj.systemId ].filter( a => a ).join( '.' ) );

	const currentValues = getValues( allIds );

	const hasAtLeastOneValue = currentValues.map( ( value: any ) => {
		return getFieldValue( value );
	}).filter( ( a: string ) => {
		return a && a !== '0';
	}).length > 0;

	if ( !hasAtLeastOneValue ) {
		const errorText = customError || `At least one value is required.`;

		return errorText;
	}

	return true;
}
