import type { ReactNode } from 'react';

type MessageBoxSize = 'small' | 'medium' | 'large';
type MessageBoxVariants = 'error' | 'success' | 'neutral';

type MessageBoxProps = {
	children: ReactNode;
	className?: string;
	size?: MessageBoxSize;
	variant?: MessageBoxVariants;
	rounded?: boolean;
};


const sizeMap: Record<MessageBoxSize, string> = {
	small: 'py-2 px-4 text-sm',
	medium: 'py-4 px-8',
	large: 'p-6',
};

const variantMap: Record<MessageBoxVariants, string> = {
	error: 'border-rural-red-800 bg-rural-red-150',
	success: 'border-green-700 bg-green-50',
	neutral: 'border-rural-gray-500 bg-rural-gray-100'
};

export default function MessageBox({
	children,
	className = '',
	size = 'large',
	variant = 'error',
	rounded = false
}: MessageBoxProps ) {
	const sizeClass = sizeMap[ size ];
	const variantClass = variantMap[ variant ];
	const roundedClass = rounded ? 'rounded' : '';

	return (
		<div className={ `${ sizeClass } ${ variantClass } ${ roundedClass } ${ className } border-2 font-light` }>
			{ children }
		</div>
	);
}
