import { useFormContext, useWatch } from 'react-hook-form';

// quick and dirty hook for combining watched and saved values
export const useFormValues = ( ids: string[] ) => {
	const { getValues } = useFormContext();

	return {
		...useWatch({ name: ids }),
		...getValues()
	};
};
