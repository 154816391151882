import type { FC } from 'react';
import type { ValidateResult } from 'react-hook-form';

import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { includeAtLeastOne } from '@/services/validation';

export function withAtLeastOneValidation( WrappedComponent: FC<ValidatedComponentProps> ) {
	const NewComponent = ( props: ValidatedComponentProps ) => {
		const { question, validators } = props;

		const { getValues, setError } = useFormContext();

		const atLeastOneValidators = useMemo( () => {
			return validators.filter( validator => validator.fieldType === 'RequireAtLeastOne' );
		}, [ validators ] );

		const validationObject = useMemo( () => {
			const returnValue: Record<string, ( v: string ) => ValidateResult> = { ...question.validate };

			if ( atLeastOneValidators.length === 0 ) {
				return returnValue;
			}

			returnValue[ 'atLeastOne' ] = ( v: string ) => {
				const validator = atLeastOneValidators[ 0 ] as ValidationRequireAtLeastOne;

				return includeAtLeastOne( validator.targetSystemIds, getValues, setError, validator.customError, question.idPrefix );
			};

			return returnValue;
		}, [ atLeastOneValidators, getValues, question.idPrefix, question.validate, setError ] );

		return (
			<div>
				<WrappedComponent
					{ ...props }
					question={{
						...question,
						validate: validationObject
					}}
				/>
			</div>
		);
	};

	NewComponent.displayName = 'withAtLeastOneValidation';

	return NewComponent;
}
