import isEqual from 'lodash/isEqual';

import { compareDates, getDateFromSquidex } from '@/services/dates';
import { accumulateValues } from '@/services/questions';
import { getForm } from '@/services/webservices/forms';

export type EffectiveDateChanges = {
	formQuestions: any;
	changedForms: any;
	changedItems: any;
	originalEffectiveDate: Date | undefined;
	newEffectiveDate: Date | undefined;
};

export function isInEffectiveDate( application: any, effectiveDate: any ) {
	if ( !application.validators ) {
		return true;
	}

	const dateValidators = application.validators.filter( ( validator: any ) => validator.fieldType === 'EffectiveDateComparison' );

	if ( dateValidators.length === 0 ) {
		return true;
	}

	return dateValidators.every( ( validator: any ) => {
		return compareDates( getDateFromSquidex( validator.date ), effectiveDate, validator.timePeriod );
	});
}

export async function getEffectiveDateChanges( applications: any[], questionnaires: any[], originalEffectiveDate?: Date, newEffectiveDate?: Date ): Promise<EffectiveDateChanges> {
	let formQuestions: any = {};

	const changedItems: any = [];
	const changedForms: any = [];

	for ( const application of [ ...applications, ...questionnaires ] ) {
		const applicationDetails = await getForm( application.applicationTypeGuid );

		formQuestions[ applicationDetails.id ] = {
			title: applicationDetails.title,
			questions: []
		};

		const originalFormVisibility = applicationDetails.validation.filter( ( validator: any ) => validator.fieldType === 'EffectiveDateComparison' ).every( ( validator: any ) => compareDates( getDateFromSquidex( validator.date ), originalEffectiveDate, validator.timePeriod ) );
		const newFormVisibility = applicationDetails.validation.filter( ( validator: any ) => validator.fieldType === 'EffectiveDateComparison' ).every( ( validator: any ) => compareDates( getDateFromSquidex( validator.date ), newEffectiveDate, validator.timePeriod ) );

		if ( originalFormVisibility !== newFormVisibility ) {
			changedForms.push({
				formId: applicationDetails.id,
				formTitle: applicationDetails.title,
				originalValue: originalFormVisibility,
				newValue: newFormVisibility,
				questionTitle: ''
			});
		}

		accumulateValues( applicationDetails.questions, ( question: any ) => {
			if ( question.validation && question.validation.length > 0 ) {
				const hasDateComparison = question.validation.some( ( validator: any ) => validator.fieldType === 'EffectiveDateComparison' );
				
				if ( hasDateComparison ) {
					formQuestions[ applicationDetails.id ].questions.push( question );
				}
			}
		});
	}

	Object.entries( formQuestions ).forEach( ( [ formKey, formValues ]: [ string, any ] ) => {
		formValues.questions.forEach( ( question: any ) => {
			const dateValidators = question.validation.filter( ( validator: any ) => validator.fieldType === 'EffectiveDateComparison' );

			const originalShow = dateValidators.every( ( validator: any ) => {
				return compareDates( getDateFromSquidex( validator.date ), originalEffectiveDate, validator.timePeriod );
			});

			const newShow = dateValidators.every( ( validator: any ) => {
				return compareDates( getDateFromSquidex( validator.date ), newEffectiveDate, validator.timePeriod );
			});

			if ( originalShow !== newShow ) {
				let questionTitle = question.label || question.title || question.description;

				if ( question.parentLabel ) {
					questionTitle = `${ question.parentLabel } | ${ question.label }`;
				}

				changedItems.push({
					formId: formKey,
					formTitle: formValues.title,
					originalValue: originalShow,
					newValue: newShow,
					questionTitle
				});
			}
		});
	});

	return {
		formQuestions,
		changedForms,
		changedItems,
		originalEffectiveDate,
		newEffectiveDate
	};
}

// get value regardless of value type (object/array/string)
export function getFieldValue( value: any ) {
	if ( !value ) {
		return '';
	}

	if ( typeof value === 'string' || Array.isArray( value ) ) {
		return value;
	}

	// otherwise, it's an object from a select { label: '', value: '' }
	if ( typeof value === 'object' ) {
		return value.value;
	}

	return '';
}

export function isMatchedValue( matchedValue: any, comparisonValue: any ) {
	if ( Array.isArray( matchedValue ) ) {
		return matchedValue.includes( comparisonValue );
	}

	return isEqual( matchedValue, comparisonValue )
}
