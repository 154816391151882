import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend( timezone )
dayjs.extend( utc )
dayjs.extend( customParseFormat );
dayjs.extend( isSameOrAfter );

export function unserializeDate( dateStr: string | Date | undefined ) {
	if ( !dateStr ) {
		return undefined;
	}

	if ( dateStr instanceof Date ) {
		return dateStr;
	}

	return dayjs( dateStr, 'YYYY-MM-DD[T]HH:mm:ss[.SSS][Z]' ).toDate();
}

/**
 * formats a given UTC Date Time as Browser Local Time
 * @param dateStr
 */
export function formatDateTime( dateStr: string ) {
	return !dateStr ? '' : dayjs( dateStr ).format( 'MM-DD-YYYY HH:mm:ss' );
}

/**
 * Formats a givne UTC Date Time in CT timezone
 * @param dateStr
 */
export function formatDateTimeInCst( dateStr: string ) {
	return !dateStr ? '' : dayjs( dateStr ).tz( 'America/Chicago' ).format( 'MM-DD-YYYY HH:mm:ss' );
}

export function formatDate( dateStr: string ) {
	return !dateStr ? '' : dayjs( dateStr ).format( 'MM-DD-YYYY' );
}

export function toDateTime( date: Date ) {
	return !date ? '' : dayjs( date ).format( 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]' )
}

export function getFormattedNow() {
	return dayjs().format( 'MM-DD-YYYY HH:mm' );
}

export function isValidDate( date: any ) {
	if ( isNaN( date ) ) {
		return false;
	}

	return date instanceof Date;
}

export function getDateFromSquidex( dateStr: string ) {
	return dayjs( dateStr, 'MM/DD/YYYY HH:mm:ss' ).toDate();
}

export function compareDates( date1: any, date2: any, comparison: 'before' | 'after' ): boolean {
	switch ( comparison ) {
		case 'before':
			return dayjs( date2 ).isBefore( date1, 'day' );
		case 'after':
			return dayjs( date2 ).isSameOrAfter( date1, 'day' );
		default:
			throw new Error( 'Unknown comparison type' );
	}
}
