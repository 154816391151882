import { withVisibilityValidation } from '@/components/Validators/withVisibilityValidation';
import { withFieldComparisonValidation } from '@/components/Validators/withFieldComparisonValidation';
import { withAtLeastOneValidation } from '@/components/Validators/withAtLeastOneValidation';
import { withSelectOneFieldComparisonValidation } from '@/components/Validators/withSelectOneFieldComparison';
import { withSetValueBasedOnFieldValidation } from '@/components/Validators/withSetValueBasedOnField';

function Validated({
	OriginalComponent,
	question,
	shouldHideNoResponses
}: ValidatedComponentProps ) {
	return (
		<OriginalComponent
			{ ...question }
			shouldHideNoResponses={ shouldHideNoResponses }
		/>
	);
}

export const FullyValidated = withFieldComparisonValidation(
	withAtLeastOneValidation(
		withSelectOneFieldComparisonValidation(
			withSetValueBasedOnFieldValidation(
				withVisibilityValidation( Validated )
			)
		)
	)
);
