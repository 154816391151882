import type { TableEmbeddedFormProps } from '@/components/FormElements/TableEmbeddedForm/TableEmbeddedForm';

import dynamic from 'next/dynamic';

import { FullyValidated } from '@/components/Validated';

import { useIsExporting } from '@/hooks/useIsExporting';

type ElementLoaderProps = {
	isReadonly?: boolean;
	marginBottom?: boolean;
	question: Question;
	required?: boolean;
	shouldHideNoResponses?: boolean;
};

const ComponentMap: PartialRecord<ComponentList, any> = {
	'Checkbox': dynamic( () => import( '@/components/FormElements/CheckboxList' ) ),
	'CppReadOnly': dynamic( () => import( '@/components/FormElements/CppReadOnly' ) ),
	'Date': dynamic( () => import( '@/components/FormElements/Date' ) ),
	'Description': dynamic( () => import( '@/components/FormElements/Description' ) ),
	'InputGroup': dynamic( () => import( '@/components/FormElements/InputGroup' ) ),
	'MultiItem': dynamic( () => import( '@/components/FormElements/MultiItem' ) ),
	'OccurrenceForm': dynamic( () => import( '@/components/FormElements/OccurrenceForm' ) ),
	'QuestionTable': dynamic( () => import( '@/components/FormElements/QuestionTable' ) ),
	'Radio': dynamic( () => import( '@/components/FormElements/RadiobuttonList' ) ),
	'ReadOnly': dynamic( () => import( '@/components/FormElements/ReadOnly' ) ),
	'Section': dynamic( () => import( '@/components/FormElements/Section' ) ),
	'Select': dynamic( () => import( '@/components/FormElements/Select' ) ),
	'Supporting Business': dynamic( () => import( '@/components/FormElements/SupportingBusiness' ) ),
	'Table': dynamic( () => import( '@/components/FormElements/Table' ) ),
	'Text': dynamic( () => import( '@/components/FormElements/Text' ) ),
	'YesNo': dynamic( () => import( '@/components/FormElements/YesNo' ) ),
	'WithdrawalExpenseLimits': dynamic ( () => import ('@/components/FormElements/WithdrawalLimit') ),
	'MailingAddress': dynamic ( () => import( '@/components/ValidationGroups/Address') ),
	'Applicant': dynamic ( () => import('@/components/ValidationGroups/Applicant') ),
	'Mortgagee': dynamic ( () => import( '@/components/ValidationGroups/Mortgagee') ),
	'ScheduledProperty': dynamic ( () => import('@/components/ValidationGroups/ScheduledProperty') ),
	'AdditionalInsureds': dynamic ( () => import( '@/components/ValidationGroups/AdditionalInsureds') ),
	'AdditionalApplicationsChecklist': dynamic ( () => import( '@/components/AdditionalAppsChecklist') ),
	'TableEmbeddedForm': dynamic ( () => import('@/components/FormElements/TableEmbeddedForm/TableEmbeddedForm')),
	'EmbeddedFormWrapper': dynamic ( () => import('@/components/FormElements/TableEmbeddedForm/EmbeddedForm') ),
	'SyncedAddress': dynamic( () => import( '@/components/FormElements/SyncedAddress' ) )
};

function NullComponent() {
	return null;
}

function ElementLoader({
	marginBottom = true,
	isReadonly = false,
	question,
	shouldHideNoResponses = false
}: ElementLoaderProps ) {
	const { isBlankExport } = useIsExporting();

	let CurrentComponent = isReadonly ? ComponentMap[ 'ReadOnly' ] : ComponentMap[ question.fieldType as ComponentList ] || NullComponent;

	if ( question.validation && question.validation.length > 0 ) {
		return (
			<div className={ marginBottom ? 'mb-4' : '' }>
				<FullyValidated
					OriginalComponent={ CurrentComponent }
					validators={ question.validation }
					question={ question }
					shouldHideNoResponses={ shouldHideNoResponses }
				/>
			</div>
		);
	}

	if ( question.fieldType === 'TableEmbeddedForm' && isBlankExport ) {
		CurrentComponent = ComponentMap[ 'Section' as ComponentList ];
		
		let embeddedForms = question as TableEmbeddedFormProps;
		
		return (
			<>
				{ embeddedForms.embeddedForms.map( ef => (
					<CurrentComponent
						key={ ef.id }
						title={ ef.title }
						questions={ ef.questions }
						shouldHideNoResponses={ shouldHideNoResponses }
					/>
				))}
			</>
		);
	}

	return (
		<div className={ marginBottom ? 'pb-4' : '' }>
			<CurrentComponent
				{ ...question }
				shouldHideNoResponses={ shouldHideNoResponses }
			/>
		</div>
	);
}

export default ElementLoader;
