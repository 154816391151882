import type { FC } from 'react';

import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { useCpp } from '@/hooks/useCpp';
import { getFieldValue } from '@/services/forms';
import { filterUniqueStrings } from '@/services/utilities';

type ComponentWithListenersProps = {
	WrappedComponent: FC<ValidatedComponentProps>;
	matchedValidators: any[];
	props: ValidatedComponentProps;
};

function removePrefix( systemId: string, idPrefix: string ) {
	return systemId.split( idPrefix + '.' )[ 1 ];
}

function useWholeForm() {
	const { cppData } = useCpp();

	const mappedFormData = useMemo( () => {
		let returnData = {};

		cppData.applications.forEach( ( application: any ) => {
			returnData = {
				...returnData,
				...application.formData
			};
		});

		return returnData;
	}, [ cppData ] );

	return {
		...mappedFormData,
		...useWatch()
	};
}

function compareValues( comparison: any, idPrefix: string, watchedValuesObject: any ) {
	const comparisonId = comparison.usePrefix ? comparison.systemId : removePrefix( comparison.systemId, idPrefix );
	const comparisonValue = comparison.value;
	const currentValue = watchedValuesObject[ comparisonId ];

	switch ( comparison.comparisonType ) {
		case 'equals':
			return comparisonValue === currentValue;
		case 'notequals':
			return comparisonValue !== currentValue;
		default:
			console.error( `Implementation for comparison value of ${ comparison.comparisonType } does not exist.` );
			return false;
	}
}

function ComponentWithListeners({
	WrappedComponent,
	matchedValidators,
	props
} : ComponentWithListenersProps ) {
	const [ valueObject, setValueObject ] = useState( {} );

	const { question } = props;

	const { setValue } = useFormContext();

	const idsToWatch = useMemo( () => {
		return matchedValidators.map( validator => {
			return validator.comparisons.map( ( comparison: any ) => {
				return comparison.usePrefix ? comparison.systemId : removePrefix( comparison.systemId, question.idPrefix );
			});
		})
		.flat()
		.filter( filterUniqueStrings );
	}, [ matchedValidators, question.idPrefix ] );

	const watchedValues = useWholeForm();

	const watchedValuesObject = useMemo( () => {
		let returnObject: Record<string, any> = {};

		idsToWatch.forEach( systemId => {
			returnObject[ systemId ] = getFieldValue( get( watchedValues, systemId, '' ) );
		});

		return returnObject;
	}, [ idsToWatch, watchedValues ] );

	useEffect( () => {
		if ( isEqual( watchedValuesObject, valueObject ) ) {
			// they're equal so we don't need to do anything
			return;
		}

		matchedValidators.forEach( validator => {
			let validatorMatches = true;

			if ( validator.requireEvery ) {
				validatorMatches = validator.comparisons.every( ( comparison: any ) => {
					return compareValues( comparison, question.idPrefix, watchedValuesObject );
				});
			} else {
				validatorMatches = validator.comparisons.some( ( comparison: any ) => {
					return compareValues( comparison, question.idPrefix, watchedValuesObject );
				});
			}

			if ( validatorMatches ) {
				setValue( question.systemId, validator.newValue );
				setValueObject( watchedValuesObject );
			}
		});
	}, [ matchedValidators, question.idPrefix, question.systemId, setValue, setValueObject, valueObject, watchedValuesObject ] );

	return <WrappedComponent { ...props } />;
}

export function withSetValueBasedOnFieldValidation( WrappedComponent: FC<ValidatedComponentProps> ) {
	const NewComponent = ( props: ValidatedComponentProps ) => {
		const { validators } = props;

		const matchedValidators = useMemo( () => {
			return validators.filter( validator => validator.fieldType === 'SetValueBasedOnField' );
		}, [ validators ] );

		if ( matchedValidators.length === 0 ) {
			return <WrappedComponent { ...props } />;
		}

		return (
			<ComponentWithListeners
				props={ props }
				matchedValidators={ matchedValidators }
				WrappedComponent={ WrappedComponent }
			/>
		);
	};

	NewComponent.displayName = 'withSetValueBasedOnFieldValidation';

	return NewComponent;
}
