import type { FC } from 'react';

import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { useCpp } from '@/hooks/useCpp';
import { useCppForm } from '@/hooks/useCppForm';
import { useFormValues } from '@/hooks/useFormValues';
import { useIsExporting } from '@/hooks/useIsExporting';
import { getDateFromSquidex, compareDates, toDateTime, unserializeDate } from '@/services/dates';
import { getFieldValue, isMatchedValue } from '@/services/forms';
import { filterUniqueStrings } from '@/services/utilities';

// Per B-107430, WRK dropdowns should always be hidden from blank PDF export.
// [TODO]: If this needs to happen more in the future, we should add an option
// to the Squidex schema that specifies an item should always be hidden in blank PDFs
const idsToAlwaysHide = [
	'C2392.excess.scheduleUnderlyingInsuranceCoverages.wrkLimits',
	'C2391.scheduleUnderlyingInsuranceCoverages.wrkLimits'
];

function isInEffectiveRange( validator: ValidationEffectiveDate, effectiveDate: string ): boolean {
	return compareDates( getDateFromSquidex( validator.date ), unserializeDate( effectiveDate ), validator.timePeriod );
}

export function withVisibilityValidation( WrappedComponent: FC<ValidatedComponentProps> ) {
	const NewComponent = ( props: ValidatedComponentProps ) => {
		const { question, validators } = props;

		const { systemId, title } = question;

		const { cppData } = useCpp();
		const { sectionHiders } = useCppForm();
		const { unregister } = useFormContext();
		const { isBlankExport, isExporting, exportEffectiveDate } = useIsExporting();

		const effectiveDateValidators = useMemo( () => {
			return validators.filter( validator => validator.fieldType === 'EffectiveDateComparison' );
		}, [ validators ] );
	
		const comparisonValidators = useMemo( () => {
			return validators.filter( validator => validator.fieldType === 'ValueComparison' );
		}, [ validators ] );


		// 1. Get a list of IDs that need to have their values checked
		const idsToWatch = useMemo( () => {
			return comparisonValidators
				.map( validator => ( validator as ValidationCompare ).comparisons )
				.flat()
				.map( comparison => comparison.systemId )
				.filter( filterUniqueStrings );
		}, [ comparisonValidators ] );
	
		const watchedValues = useFormValues( idsToWatch );
	
		const watchedObject = useMemo( () => {
			let returnObject: Record<string, any> = {};
	
			idsToWatch.forEach( ( systemId ) => {
				returnObject[ systemId ] = getFieldValue( get( watchedValues, systemId, '' ) );
			});
	
			return returnObject;
		}, [ watchedValues, idsToWatch ] );


		// 2. Loop through validators and determine visibility status
		const effectiveDateCheck = useMemo( () => {
			if ( effectiveDateValidators.length === 0 ) {
				return undefined;
			}

			return effectiveDateValidators.every( validator => {
				let comparisonDate = exportEffectiveDate ? toDateTime( exportEffectiveDate ) : cppData.quoteEffectiveDate;

				return isInEffectiveRange( validator as ValidationEffectiveDate, comparisonDate );
			});
		}, [ cppData.quoteEffectiveDate, effectiveDateValidators, exportEffectiveDate ] );

		const watchedValuesCheck = useMemo( () => {
			if ( comparisonValidators.length === 0 ) {
				return undefined;
			}

			const check = comparisonValidators
				.map( validator => {
					if ( ( validator as ValidationCompare ).requireEvery ) {
						return ( validator as ValidationCompare ).comparisons.every( comparison => {
							const matchedValue = watchedObject[ comparison.systemId ];

							return isMatchedValue( matchedValue, comparison.value );
						});
					} else {
						return ( validator as ValidationCompare ).comparisons.some( comparison => {
							const matchedValue = watchedObject[ comparison.systemId ];

							return isMatchedValue( matchedValue, comparison.value );
						});
					}
				});

			return check.some( a => a );
		}, [ watchedObject, comparisonValidators ] );

		const isHidden = useMemo( () => {
			return [ effectiveDateCheck, watchedValuesCheck ].filter( a => a !== undefined ).some( a => !a );
		}, [ effectiveDateCheck, watchedValuesCheck ] );


		// 3. See if it needs to be hidden in exports
		// Per B-107430, WRK dropdowns should always be hidden from blank PDF export.
		// [TODO]: If this needs to happen more in the future, we should add an option
		// to the Squidex schema that specifies an item should always be hidden in blank PDFs
		const isAlwaysHiddenInExports = useMemo( () => {
			if ( !systemId ) {
				return false;
			}
	
			if ( idsToAlwaysHide.includes( systemId.replace( /\[\d{1,}\]/, '' ) ) ) {
				return true;
			}
	
			return false;
		}, [ systemId ] );


		// 4. Set sidebar visibility
		useEffect( () => {
			if ( !title || !sectionHiders ) {
				return;
			}
	
			if ( !isBlankExport ) {
				const sectionTitlesFunction = sectionHiders.get( title );
		
				if ( !!sectionTitlesFunction ) {
					sectionTitlesFunction( isHidden );
				}
			}
		}, [ isBlankExport, isHidden, sectionHiders, title ] );


		// 5. If the field changes visibility, see if we need to reset the value of it
		useEffect( () => {
			if ( isHidden && systemId && !isExporting ) {
				unregister( systemId );
			}
		}, [ isExporting, isHidden, systemId, unregister ] );

		if ( ( isBlankExport && isAlwaysHiddenInExports ) || ( isBlankExport && effectiveDateCheck === false ) || ( !isBlankExport && isHidden ) ) {
			return null;
		}

		return <WrappedComponent { ...props } />;
	}

	NewComponent.displayName = 'withVisibilityValidators';

	return NewComponent;
}
